import React, { useState, useEffect } from 'react'

import upArrow from "../../images/arrow-up.svg";

import "./scroll.scss";

const ArrowButton = ({ showBelow }) => {

  const [show, setShow] = useState(showBelow ? false : true)

  const handleScroll = () => {
    if (window.pageYOffset > showBelow) {
      if (!show) setShow(true)
    } else {
      if (show) setShow(false)
    }
  }

  const handleClick = () => {
    window[`scrollTo`]({ top: 0, behavior: `smooth` })
  }

  useEffect(() => {
    if (showBelow) {
      window.addEventListener(`scroll`, handleScroll)
      return () => window.removeEventListener(`scroll`, handleScroll)
    }
  })

  return (
    <span className="to-the-top-container" onClick={handleClick}>
      {show &&
        <img className="toTopImg" src={upArrow} alt="To Top" aria-label="to top" loading="lazy" component="span" />
      }
    </span>
  );
}

export default ArrowButton;